<template>
    <div class="chapter-preview">
        <ul>
            <li v-for="image in imageList" :key="image.imgUrl">
                <img :src="$config.cdn + $utils.filterImagePath(image.imgUrl)">
            </li>
        </ul>
    </div>
</template>

<script>
/*
* @Author: daipeng
 * @Date: 2018-11-30 17:02:59
 * @LastEditors: VSCode
 * @LastEditTime: 2018-11-30 17:21:14
 * @Description: 章节内容预览
 * @Company: 成都二次元动漫
 */
export default {
    props: {
        imageList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped>
    .chapter-preview{
        width: 100%;
        padding: 10px;
        overflow-x: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        ul{
            list-style-type: none;
            width: 100%;
            li{
                width: 100%;
                img{
                    width: 100%;
                }
            }
        }
    }
</style>
