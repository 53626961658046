<template>
  <div
    class="edit-chapter flex-column"
    v-loading="isLoading"
    element-loading-text="保存中"
    element-loading-spinner="el-icon-loading"
  >
    <el-form
      ref="chapterForm"
      :model="chapterData"
      :rules="type ? chapterDataRules : {}"
      label-width="200px"
    >
      <el-form-item label="章节编号：" prop="chapter_sn">
        <el-col :span="12">
          <span v-if="type === 'view'" class="edit-chapter-name">{{
            chapterData.chapter_sn
          }}</span>
          <el-input
            v-else
            :style="type === 2 ? 'display:none' : ''"
            v-model="chapterData.chapter_sn"
            placeholder="最多1 - 30个数字、字母、汉字或符号"
          />
        </el-col>
      </el-form-item>
      <el-form-item label="章节名：" prop="chapter_name">
        <el-col :span="12">
          <span v-if="type === 'view'" class="edit-chapter-name">{{
            chapterData.chapter_name
          }}</span>
          <el-input
            v-else
            :style="type === 2 ? 'display:none' : ''"
            v-model="chapterData.chapter_name"
            placeholder="最多1 - 40个字符"
          />
        </el-col>
      </el-form-item>
      <el-form-item label="下载：" prop="allow_down">
        <template v-if="type === 'view'">{{
          chapterData.allow_down === 1 ? '支持下载' : '不支持下载'
        }}</template>
        <template v-else>
          <el-radio-group v-model="chapterData.allow_down">
            <el-radio :label="1">支持下载</el-radio>
            <el-radio :label="0">不支持下载</el-radio>
          </el-radio-group>
        </template>
      </el-form-item>
      <el-form-item label="章节价格：" prop="charge_type">
        <template v-if="type === 'view'">{{
          chapterData.charge_type === 0 ? '免费' : '付费'
        }}</template>
        <template v-else>
          <el-radio-group
            v-model="chapterData.charge_type"
            @change="
              (status) =>
                status === 0 ? (chapterData.chapter_price = undefined) : status
            "
          >
            <el-radio :label="0">免费</el-radio>
            <el-radio :label="1" class="chapter-price">付费</el-radio>
          </el-radio-group>
          <template v-if="chapterData.charge_type">
            <!-- <div class="search-box-message"><i class="el-icon-info"></i>目前动态漫仅支持VIP付费</div> -->
            <el-input-number
              v-model="chapterData.chapter_price"
              :min="0"
              :max="500"
            ></el-input-number>
            钻石
            <div class="search-box-message">
              <i class="el-icon-info"></i
              >选择收费填写数字价格，收费价格必须大于0，最大限制500钻石，100钻石=1元
            </div>
          </template>
        </template>
      </el-form-item>
      <el-form-item
        v-if="chapterData.charge_type === 1"
        label="试看时长："
        prop="free_sec"
      >
        <el-col :span="14">
          <span class="head-label">试看至&nbsp;</span>
          <el-time-picker
            value-format="HH:mm:ss"
            :picker-options="{
              selectableRange,
            }"
            v-model="chapterData.free_sec"
          >
          </el-time-picker>
        </el-col>
      </el-form-item>
      <el-form-item label="片头：" prop="head_sec">
        <span class="head-label">视频开头 ~ </span>
        <el-time-picker
          :disabled="!chapterData.chapter_video_id"
          value-format="HH:mm:ss"
          :picker-options="{
            selectableRange,
          }"
          v-model="chapterData.head_sec"
        >
        </el-time-picker>
        <span class="head-label tar ml10">片尾: </span>
        <el-time-picker
          :disabled="!chapterData.chapter_video_id"
          class="ml10"
          value-format="HH:mm:ss"
          :picker-options="{
            selectableRange,
          }"
          v-model="chapterData.tail_sec"
        >
        </el-time-picker>
        <span class="head-label"> ~ 视频结束</span>
      </el-form-item>
      <el-form-item label="上传动态漫：" prop="chapter_video_id">
        <!-- <chapterVideo :file_size="chapterData.size" :duration.sync="chapterData.duration" :type="type" :chapter_video_id.sync="chapterData.chapter_video_id" :chapter_sn="chapterData.chapter_sn"/> -->
        <uploadVideo
          :file_size="chapterData.size"
          :duration.sync="chapterData.duration"
          :type="type"
          :video_id.sync="chapterData.chapter_video_id"
          :chapter_sn="chapterData.chapter_sn"
          uploadType="getAnChapterVideoToken"
          videoAddress="getAnChapterVideoPlayAuth"
        />
      </el-form-item>
      <el-form-item label="章节图：" prop="chapter_cover_16_8">
        <chapterCover
          :type="type"
          :chapterCover168.sync="chapterData.chapter_cover_16_8"
          :chapter_cover_domain.sync="chapterData.chapter_cover_domain"
        />
      </el-form-item>
      <el-form-item
        label="发布平台："
        prop="auth_platforms"
        class="comic-info-platform"
      >
        <template v-if="type === 'view'">
          <span
            v-for="platform in chapterData.platforms"
            :key="platform.platformId"
            class="mr10"
            >{{ platform.platformName }}</span
          >
        </template>
        <template v-else>
          <el-row v-for="item in mergedAuthPlatforms" :key="item.platform_id">
            <el-col :span="2">
              <el-checkbox v-model="item.check">{{
                item.platform_name
              }}</el-checkbox>
            </el-col>
            <el-col :span="16">
              <el-radio-group v-model="item.is_timing">
                <el-radio :label="0">立即发布</el-radio>
                <el-radio :label="1">定时发布</el-radio>
              </el-radio-group>
              <el-date-picker
                class="ml10"
                v-if="item.is_timing"
                v-model="item.publish_time"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-col>
          </el-row>
        </template>
      </el-form-item>
      <dialogPlus
        :visible.sync="dialogData.visible"
        :title.sync="dialogData.title"
        width="530px"
        :model="dialogData.data"
        :heightFull="true"
      >
        <template v-if="dialogData.data.dialogName === 'agreement'">
          <agreement />
          <div slot="footer">
            <el-button @click="dialogCloseHandle">取消</el-button>
            <el-button type="primary" @click="agreementHandle">同意</el-button>
          </div>
        </template>
      </dialogPlus>
    </el-form>
    <div v-if="type !== 'view'" class="mmiddle">
      <el-checkbox v-model="agreement">我同意</el-checkbox>
      <el-button
        type="text"
        @click.stop="
          changeDialog(true, '用户协议', 1, { dialogName: 'agreement' })
        "
        size="medium"
        >二次元漫画分发中心服务协议</el-button
      >
    </div>
    <!-- buttons -->
    <div v-if="type !== 'view'" class="mmiddle btns">
      <el-button type="primary" @click="submitFormHandle('chapterForm')"
        >保存</el-button
      >
      <el-button @click="cancelHandle('chapterForm')">取消</el-button>
    </div>
    <el-dialog
      class="fulldialog"
      title="预览"
      width="800px"
      :append-to-body="true"
      :visible.sync="showDialog"
    >
      <el-image
        :src="`${chapterData.chapter_cover_domain}${chapterData.chapter_cover_16_8}`"
      ></el-image>
    </el-dialog>
  </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-17 14:58:30
 * @LastEditTime: 2018-09-17 14:58:30
 * @Description: 编辑章节-具体实现
 * @Company: 成都二次元动漫
 */
import { formMixin, dialogMixin, dataMixin } from '@/mixins'
import chapterCover from './chapterCover'
import chapterVideo from './chapterVideo'
import uploadVideo from '../../../../components/upload.vue'
import previewChapterContent from './previewChapterContent'
import agreement from '@/views/main/other/agreement'
import { timeFormat } from '@/libs/utils'

export default {
  mixins: [formMixin, dialogMixin, dataMixin],
  props: {
    currentChapterData: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      timer: [],
      showDialog: false,
      fullDialogImage: '',
      isLoading: false,
      maxlength: 200,
      publicClass: [],
      type: 'add', // 编辑类型: 0 view, 1 edit, 2 add
      auth_platforms: [], // { platform_id: null, publishTime: null, name: '' }
      agreement: true, // 同意协议
      isChange: false, // 是否编辑
      isSubmit: false, // 是否提交
      prevChapterId: null, // 多章节保存中的id暂存
      submitIds: [], // 多章节编辑模式中，已保存的章节ids
      comicInfo: {},
      mergedAuthPlatforms: [],
      chapterData: {
        chapter_id: null,
        animation_id: null,
        chapter_name: undefined,
        chapter_sn: null,
        charge_type: 0,
        allow_down: 1,
        chapter_price: 0,
        chapter_cover_16_8: null,
        chapter_video_id: '',
        // 发布平台
        auth_platforms: [],
        chapter_cover_domain: '',
      },
      main_chapter_price: null, // 漫画主体设置的章节价格
      selectableRange: '',
      chapterDataRules: {
        chapter_sn: [
          { required: true, message: '请输入章节编号', trigger: 'blur' },
          { min: 0, max: 30, message: '最多30个字符', trigger: 'blur' },
        ],
        chapter_name: [{ max: 40, message: '最多40个字符', trigger: 'blur' }],
        chapter_price: [
          {
            required: true,
            validator: this.validchapter_price,
            trigger: 'blur',
          },
        ],
        allow_down: [{ required: true }],
        free_sec: [{ required: true, message: '请填写试看时长' }],
        charge_type: [{ required: true }],
        chapter_video_id: [{ required: true, message: '请上传动态漫视频' }],
        chapter_cover_16_8: [
          { required: true, message: '请上传章节封面', trigger: 'change' },
        ],
        auth_platforms: [
          {
            required: true,
            validator: this.validauth_platforms,
            trigger: 'blur',
          },
        ],
      },
      pickerDisabled: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7
        },
      },
    }
  },
  watch: {
    currentChapterData() {
      this.initQuery()
    },
    'chapterData.duration'() {
      // code
      this.selectableRange = `00:00:00 - ${this.$utils.reCovertTime(
        this.chapterData.duration
      )}`
    },
    chapterData: {
      deep: true,
      handler() {
        if (this.type === 'edit') this.isChange = this.checkEditChange()
        else if (this.type === 'add') this.isChange = this.checkAddChange()
      },
    },
  },
  beforeCreate() {
    this.$emit('update:active', 2)
  },
  destroyed() {
    this.$root.$off('fullscreen')
  },
  async created() {
    this.initQuery()
    await this.initData()
    this.$root.$on('fullscreen', this.fullscreenHandle)
    this.$root.$on('previewChapterContent', this.previewChapterContent)
  },
  methods: {
    stopRefreshGreenImgProcessData() {
      for (let item of this.timer) {
        clearTimeout(item)
      }
    },
    // 初始化传递参数
    async initQuery() {
      this.resetDataField('chapterData')
      const {
        type = 'add',
        animation_id,
        animation_name,
        authorName,
        chapter_id = undefined,
        chapter_price,
      } = this.$route.query
      this.type = type
      this.main_chapter_price = chapter_price
      this.chapterData = {
        ...this.chapterData,
        ...this.currentChapterData,
        animation_id,
        animation_name,
        authorName,
        chapter_id,
        chapter_price,
      }
      if (this.type === 'add') {
        this.isSubmit = false
        this.chapterData.sourceObj = this.$utils.cloneDeep(this.chapterData)
        this.originData = this.$utils.cloneDeep(this.chapterData)
        if (this.chapterData.type) this.type = this.chapterData.type
      }
    },
    // 初始化数据
    async initData() {
      await this.getComicPlatformList()
      await this.getComicChapterData()
      if (this.type === 'add') {
        this.auth_platforms.map((item) => {
          this.mergedAuthPlatforms.push(
            Object.assign({}, item, { check: true, is_timing: 0 })
          )
        })
      } else {
        this.auth_platforms.map((item) => {
          let resItem = this.chapterData.auth_platforms.find((subItem) => {
            return subItem.platform_id === item.platform_id
          })
          if (resItem) {
            if (resItem.is_timing === 0) {
              delete resItem.publish_time
            }
            return this.mergedAuthPlatforms.push(
              Object.assign({}, item, resItem, { check: true })
            )
          }
          this.mergedAuthPlatforms.push(item)
        })
      }
    },
    // 查看放大图
    fullscreenHandle(src) {
      if (!src) return
      this.showDialog = true
      this.fullDialogImage = src
    },
    // 预览所有上传的章节内容图片
    previewChapterContent(list) {
      this.changeDialog(true, '预览已上传章节内容', 0, {
        dialogName: 'previewChapterContent',
      })
    },
    // date-picker选择
    datePickerChange(platform) {
      if (new Date(platform.publishTime).getTime() < Date.now()) {
        this.$message.error('只能选择当前时间以后的时间')
        platform.publishTime = null
        return
      }

      const origin = this.getOriginPlatform(platform)
      if (
        origin.publishTime !== null &&
        new Date(platform.publishTime).getTime() <
          new Date(origin.publishTime).getTime()
      ) {
        const time = timeFormat(origin.publishTime, 'yyyy-MM-dd hh:mm:ss')
        this.$message.error({
          message: `时间必须大于等于渠道发布时间：${time}`,
          duration: 5000,
        })
        platform.publishTime = null
      }
    },
    // 获取漫画平台列表
    async getComicPlatformList() {
      return this.$api('getAnComicCheckedPlatformList', {
        animation_id: this.$route.query.animation_id,
      }).then(({ data }) => {
        data.map((platform) => {
          this.auth_platforms.push({
            platform_id: platform.platform_id,
            platform_name: platform.platform_name,
          })
        })
      })
    },
    // 获取漫画章节数据
    getComicChapterData() {
      if (this.type === 'add') {
        this.chapterData.charge_type = this.main_chapter_price > 0 ? 1 : 0
        return
      }
      const { animation_id, chapter_id } = this.chapterData
      return this.$api('getAnComicChapterInfo', {
        animation_id,
        chapter_id,
      }).then(({ data }) => {
        if (this.$utils.isEmptyObject(data)) {
          this.$message.error('当前章节不存在！')
          return this.$router.back()
        }
        this.selectableRange = `00:00:00 - ${this.$utils.reCovertTime(
          data.info.duration
        )}`
        data.info.free_sec = this.$utils.reCovertTime(data.info.free_sec)
        if (data.info.head_sec) {
          data.info.head_sec = this.$utils.reCovertTime(data.info.head_sec)
        }
        if (this.$utils.isNumber(data.info.tail_sec)) {
          data.info.tail_sec = this.$utils.reCovertTime(
            data.info.duration - data.info.tail_sec
          )
        }
        // if (data.platforms) {
        //     const _auth_platforms = data.platforms.map(task => {
        //         return this.platformList.filter(platform => {
        //             if (platform.platform_id === task.platform_id) {
        //                 platform.publish_time = task.publish_time;
        //                 platform.is_timing = task.publish_time ? 1 : 0;
        //                 return true;
        //             }
        //         })[0];
        //     });
        //     data.auth_platforms = _auth_platforms;
        // }
        data.charge_type = data.chapter_price > 0 ? 1 : 0
        this.chapterData = {
          ...this.chapterData,
          ...data.info,
          auth_platforms: data.platforms,
        }
      })
    },
    // 验证发布平台
    validauth_platforms(rule, value, callback) {
      if (!value.length) return callback(new Error('请选择发布平台'))
      // 如果选了定时发布 但没选时间
      if (value.is_timing === 1 && !value.publish_time) {
        callback(new Error('请填写定时发布时间'))
      }
      callback()
    },
    // 验证章节价格
    validchapter_price(rule, value, callback) {
      if (this.chapterData.charge_type === 1 && !this.chapterData.chapter_price)
        callback(new Error('请输入章节价格'))
      else if (
        this.chapterData.charge_type === 0 &&
        this.chapterData.chapter_price
      )
        this.chapterData.chapter_price = undefined
      else callback()
    },
    // 同意协议
    agreementHandle() {
      this.agreement = true
      this.dialogCloseHandle()
    },
    // 检查是否做了修改
    checkEditChange() {
      const chapterDataClone = this.$utils.cloneDeep(this.chapterData)
      const sourceObjClone = this.$utils.cloneDeep(chapterDataClone.sourceObj)
      Reflect.deleteProperty(chapterDataClone, 'sourceObj')
      if (JSON.stringify(chapterDataClone) === JSON.stringify(sourceObjClone))
        return false
      else return true
    },
    // 检查新增数据是否修改
    checkAddChange() {
      if (JSON.stringify(this.chapterData) === JSON.stringify(this.originData))
        return false
      else return true
    },
    // 取消, 1、取消表单验证    2、如果是add清空数据，如果是view/edit还原数据
    cancelHandle(formName) {
      this.clearFormValidate(formName)
      if (this.type === 'add') {
        this.initQuery()
        // 清除全选
        this.allPlat.checked = false
      } else {
        this.initData()
      }
    },
    covertTime(timeStr) {
      let sec = 0
      if (timeStr === undefined) {
        return undefined
      }
      timeStr.split(':').map((item, index) => {
        item = parseInt(item)
        switch (index) {
          case 0:
            sec += item * 3600
            break
          case 1:
            sec += item * 60
            break
          default:
            sec += item
        }
      })
      return sec
    },
    // 提交审核
    async submitFormHandle() {
      let auth_platforms = this.mergedAuthPlatforms.map((item) => {
        if (item.check) {
          if (item.is_timing === 0) {
            item.publish_time = undefined
          }
          const { platform_id, is_timing, publish_time } = item
          return {
            platform_id,
            is_timing,
            publish_time,
          }
        }
      })
      if (auth_platforms) {
        this.chapterData.auth_platforms = auth_platforms
      }
      const valid = await this.validateForm('chapterForm')
      if (!valid) {
        this.message && this.message.close()
        this.message = this.$message.error('章节信息填写有误!')
        return false
      } else if (!this.agreement) {
        this.$message.error('请阅读并同意用户协议')
        return false
      } else if (!this.isChange) {
        this.$message.error('章节未做修改')
        return false
      }

      let url
      if (this.type === 'add') url = 'addAnComicChapter'
      else if (this.type === 'edit') url = 'editAnComicChapter'
      this.$utils.confirm(
        '',
        `请确认保存页面中的所有内容`,
        '保存中...',
        (action, instance, done) => {
          this.isLoading = true
          done()
          const {
            animation_id,
            chapter_id,
            chapter_sn,
            chapter_name,
            chapter_video_id,
            chapter_cover_16_8,
            chapter_price,
            charge_type,
            free_sec,
            head_sec,
            tail_sec,
            allow_down,
            chapter_cover_domain,
          } = this.$utils.cloneDeep(this.chapterData)
          let auth_platforms = this.mergedAuthPlatforms.map((item) => {
            if (item.check) {
              if (item.is_timing === 0) {
                item.publish_time = undefined
              }
              const { platform_id, is_timing, publish_time } = item
              return {
                platform_id,
                is_timing,
                publish_time,
              }
            }
          })
          const postData = {
            animation_id,
            chapter_id,
            chapter_sn,
            chapter_name,
            chapter_video_id,
            chapter_cover_16_8,
            chapter_price,
            charge_type,
            free_sec,
            head_sec,
            tail_sec,
            allow_down,
            auth_platforms,
            chapter_cover_domain,
          }
          if (chapter_name === '') {
            delete postData.chapter_name
          }
          if (charge_type === 1) {
            postData.free_sec = this.covertTime(postData.free_sec)
          } else {
            postData.free_sec = 0
          }
          if (postData.head_sec) {
            postData.head_sec = this.covertTime(postData.head_sec)
          }
          if (postData.tail_sec) {
            postData.tail_sec =
              this.covertTime(this.selectableRange.split(' - ')[1]) -
              this.covertTime(postData.tail_sec)
          }
          return this.$api(url, postData)
            .then((res) => {
              this.isLoading = false
              this.isSubmit = true
              this.prevChapterId = res.data.chapter_id
              this.$message.success({
                message: '提交成功！',
                duration: '1000',
                onClose: () => {
                  const {
                    type,
                    animation_name,
                    animation_id,
                    uploaderUid,
                    authorName,
                  } = this.$route.query
                  // 如果为已有的漫画章节编辑页面，就不用跳转，否则刷新数据
                  if (this.$route.query.tabType) {
                    this.$router.back()
                  } else {
                    this.$emit('update:active', 3)
                    this.$router.replace({
                      name: 'addPublishAn',
                      query: {
                        type,
                        animation_name,
                        animation_id,
                        uploaderUid,
                        authorName,
                        chapter_price: this.main_chapter_price,
                      },
                    })
                  }
                },
              })
            })
            .catch((er) => {
              this.isLoading = false
            })
        }
      )
    },
    defaultTime(platform) {
      // 设置平台发布默认时间(小时)
      let defaultVal = '08:00:00'
      const origin = this.getOriginPlatform(platform)
      const time = origin.publishTime
      if (time) {
        const dateTime = new Date(time)
        defaultVal =
          dateTime.getHours() +
          ':' +
          dateTime.getMinutes() +
          ':' +
          dateTime.getSeconds()
      }
      return defaultVal
    },
  },
  components: {
    agreement,
    chapterCover,
    chapterVideo,
    previewChapterContent,
    uploadVideo,
  },
}
</script>
<style lang="scss">
@import '../../../../styles/var';
.edit-chapter {
  font-size: 12px;
  height: 100%;
  .head-label {
    font-size: 14px;
    color: #606266;
    width: 80px;
    display: inline-block;
  }
  .chapter-price {
    margin-right: 10px;
  }
  .el-form {
    flex-grow: 1;
    padding: 0 10px 0 0;
    overflow-x: hidden;
    overflow-y: auto;

    .autoResize {
      overflow: hidden;
      align-items: center;
    }
  }
  .search-box-message {
    font-size: 12px;
    color: $color-info;
    i {
      margin-right: 5px;
    }
  }
  &-textarea {
    position: relative;
    textarea {
      padding-bottom: 20px;
    }
    &-length {
      position: absolute;
      bottom: 5px;
      left: calc(100% + 10px);
      line-height: 1;
      color: #999;
    }
  }
  &-tips {
    font-size: 12px;
    color: #909399;
  }
  &-theme.el-checkbox {
    margin-left: 0;
    margin-right: 30px;
  }
  &-tips {
    > div {
      font-size: 12px;
      color: #999;
    }
  }
  &-platform {
    .el-checkbox-group {
      padding: 10px 0;
      .el-checkbox {
        width: 130px;
        margin-right: 10px;
      }
      .el-date-editor {
        margin-left: 10px;
      }
    }
  }
  .btns {
    margin-bottom: 10px;
  }
}
.fulldialog {
  .is-fullscreen {
    background: rgba(255, 255, 255, 0);
  }
  .el-dialog__header {
    border: none;
  }
  .el-dialog__body {
    height: calc(100% - 30px);
  }
  i.el-dialog__close {
    color: #ffff;
  }
  &-content {
    width: 700px;
    height: 375px;
    margin: 0 auto;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: relative;
  }
  &-focus {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    font-size: 36px;
    border-width: 6px;
    padding: 0 15px;
    z-index: 999999;
    height: 42px;
    font-weight: bold;
  }
}
</style>
